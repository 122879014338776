import { makeStyles, createStyles } from '@mui/styles'
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useAuth } from '@praxis/component-auth'
import BookmarkOutlinedIcon from '@mui/icons-material/BookmarkOutlined'
import CancelPresentationIcon from '@mui/icons-material/Cancel'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import RepeatOnIcon from '@mui/icons-material/RepeatOn'
import VerifiedIcon from '@mui/icons-material/Verified'
import WatchLaterIcon from '@mui/icons-material/WatchLater'
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle'
import {
  Alert,
  Typography,
  Snackbar,
  SpeedDial,
  SpeedDialIcon,
  SpeedDialAction,
} from '@mui/material'

import {
  ADD_TO_SCHEDULE,
  COMPLETE_WORK_ORDER,
  CREATE_FOLLOW_UP,
  ADD_TIME,
  CANCEL_WORK_ORDER,
  MAT_UI_LG_WIDTH_HALF,
  WORK_ORDER,
  VALIDATE_WORK_ORDER,
  globalRoutes,
  WO_DETAILS,
  CHANGE_PRIORITY,
  SCHEDULE_OR_ASSIGNMENT,
} from '../../globalConstants'
import {
  COLOR_BACKGROUND_GREY,
  COLOR_THEME_ACCENT,
  COLOR_FONT_BLUE,
  COLOR_TARGET_RED,
} from '../../globalColors'
import { setFollowUpFlow } from '../CreateWorkOrder/actionCreator'
import { getURLSearchParams, setURLSearchParams } from '../../windowManager'
import { setShouldRender } from '../ProgressOverlay/store/actionCreator'
import { postData } from '../../service/HttpService'

const useStyles = makeStyles((theme) =>
  createStyles({
    fab: {
      background: COLOR_TARGET_RED,
      '&:hover': {
        background: COLOR_TARGET_RED,
      },
    },
    dial: {
      position: 'fixed',
      bottom: theme.spacing(3),
      right: '50%',
      marginRight: -MAT_UI_LG_WIDTH_HALF,
      paddingRight: theme.spacing(3),
      [theme.breakpoints.down('lg')]: {
        marginRight: 'auto',
        right: theme.spacing(3),
        paddingRight: 0,
      },
      alignItems: 'flex-end',
    },
    typography: {
      whiteSpace: 'nowrap',
      color: COLOR_THEME_ACCENT,
    },
    icon: {
      whiteSpace: 'nowrap',
      color: COLOR_FONT_BLUE,
    },
    fabRoot: {
      width: '100%',
      boxShadow: 'none',
      background: 'none',
      transitionDelay: `0ms !important`, // remove any transition delay so that the icons don't linger in the screen after background disappears
    },
    fabIconWithText: {
      display: 'flex',
      gap: 16,
      alignItems: 'center',
      padding: 16,
      marginRight: 'auto',
    },
    iconFlex: {
      display: 'flex',
    },
    actionsContainer: {
      alignItems: 'flex-end',
      borderRadius: '2%',
      background: COLOR_BACKGROUND_GREY,
      boxShadow:
        'rgb(0 0 0 / 20%) 0px 2px 1px -1px, rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px',
      marginBottom: `${theme.spacing(2.25)} !important`,
      paddingBottom: `0 !important`,
      padding: theme.spacing(0, 1),
      position: 'relative',
      '&:after': {
        content: "''",
        position: 'absolute',
        top: '100%',
        right: 12,
        width: 0,
        height: 0,
        borderWidth: 16,
        borderTop: `solid ${theme.spacing(2)} ${COLOR_BACKGROUND_GREY}`,
        borderLeft: 'solid 16px transparent',
        borderRight: 'solid 16px transparent',
      },
    },
    actionsArrowBoxShadow: {
      '&:before': {
        bottom: theme.spacing(8),
        right: theme.spacing(3.5),
        width: theme.spacing(4),
        height: theme.spacing(4),
        [theme.breakpoints.down('lg')]: {
          right: theme.spacing(1.5),
        },
        content: "''",
        position: 'absolute',
        transform: 'rotate(45deg)',
        zIndex: -1,
        boxShadow:
          'rgb(0 0 0 / 20%) 0px 2px 1px -1px, rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px',
      },
    },
    speedDialHiddenDiv: {
      position: 'absolute',
      width: '100%',
      height: theme.spacing(5),
      right: 0,
      left: theme.spacing(1),
      bottom: 0,
      top: '100%',
    },
  }),
)

const WorkOrderSpeedDial = () => {
  const auth = useAuth()
  const navigate = useNavigate()
  const classes = useStyles()
  const dispatch = useDispatch()
  const { storeId } = useSelector((state) => state.storeInfoReducer)
  const [open, setOpen] = useState(false)
  const [error, setError] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')
  const handleClose = () => setOpen(false)
  const handleOpen = () => setOpen(true)
  const {
    wodetails,
    surveyAnswers = null,
    isDCUser,
  } = useSelector((state) => ({
    ...state.workOrderDetailsReducer,
    ...state.surveyAnswersReducer,
    ...state.userInfoReducer,
  }))
  const woOriginPersonList = wodetails?.woassigncode
  const woTypePM = wodetails?.wotype === 'PM'
  const woPersonList =
    woOriginPersonList &&
    woOriginPersonList !== null &&
    woOriginPersonList.split('-')
  const userId = auth.session.userInfo?.lanId?.toUpperCase()
  const personListIncludesUserId = woPersonList && woPersonList.includes(userId)
  const urlSearchParams = getURLSearchParams()
  const woNum = parseInt(urlSearchParams.get('wonum'))

  const { woassetname = '', wolocation = '', wonum = '' } = wodetails ?? {}
  const addToSchedule = async () => {
    const assignmentObject = {
      woNum: woNum,
      userId: userId,
      hours: 1,
      scheduledStartDate: new Date(),
      craft: wodetails.woassigncraft,
      laborCode: userId,
      siteId: storeId,
    }

    try {
      setError(false)
      dispatch(
        setShouldRender(
          true,
          `Adding WO to ${SCHEDULE_OR_ASSIGNMENT(isDCUser)}...`,
        ),
      )
      let url = '/maximo/assignment/new'

      await postData(url, JSON.stringify(assignmentObject))
      setIsSuccess(true)
      setError(false)
      setAlertMessage(`WO added to ${SCHEDULE_OR_ASSIGNMENT(isDCUser)}`)
    } catch (error) {
      if (error?.response?.data?.message === 'User already assigned') {
        setAlertMessage('User already assigned')
      } else {
        setAlertMessage(`Error adding to ${SCHEDULE_OR_ASSIGNMENT(isDCUser)}`)
      }
      setIsSuccess(false)
      setError(true)
    } finally {
      dispatch(setShouldRender(false))
      handleClose()
    }
  }

  const actions = [
    {
      icon: <RepeatOnIcon className={classes.icon} />,
      name: (
        <Typography className={classes.typography}>
          {CREATE_FOLLOW_UP}
        </Typography>
      ),
      onClick: () => {
        dispatch(
          setFollowUpFlow(true, {
            woassetname,
            wolocation,
            wonum,
            from: WO_DETAILS,
          }),
        )
        navigate({
          pathname: globalRoutes.startCreateWorkOrder,
        })
      },
    },
    {
      icon: <BookmarkOutlinedIcon className={classes.icon} />,
      name: (
        <Typography className={classes.typography}>
          {ADD_TO_SCHEDULE(isDCUser)}
        </Typography>
      ),
      onClick: () => addToSchedule(),
    },
    {
      icon: <WatchLaterIcon className={classes.icon} />,
      name: <Typography className={classes.typography}>{ADD_TIME}</Typography>,
      onClick: () =>
        setURLSearchParams([{ name: 'add_time_open', value: true }]),
    },
    ...(wodetails && wodetails.external && Number(wodetails.external) === 0
      ? [
          !woTypePM &&
            !isDCUser && {
              icon: <CancelPresentationIcon className={classes.icon} />,
              name: (
                <Typography className={classes.typography}>
                  {CANCEL_WORK_ORDER}
                </Typography>
              ),
              onClick: () =>
                setURLSearchParams([{ name: 'cancel_wo_open', value: true }]),
            },
          personListIncludesUserId &&
            ((wodetails?.classstructureid && surveyAnswers?.completedflag) ||
              !wodetails?.classstructureid) && {
              icon: <CheckCircleIcon className={classes.icon} />,
              name: (
                <Typography className={classes.typography}>
                  {COMPLETE_WORK_ORDER}
                </Typography>
              ),
              onClick: () =>
                navigate(`${globalRoutes.completeWorkOrder}#?wonum=${woNum}`),
            },
        ]
      : [
          {
            icon: <VerifiedIcon className={classes.icon} />,
            name: (
              <Typography className={classes.typography}>
                {VALIDATE_WORK_ORDER}
              </Typography>
            ),
            onClick: () =>
              navigate(`${globalRoutes.validateWorkOrder}#?wonum=${woNum}`),
          },
        ]),
    ...(isDCUser
      ? [
          {
            icon: <ChangeCircleIcon className={classes.icon} />,
            name: (
              <Typography className={classes.typography}>
                {CHANGE_PRIORITY}
              </Typography>
            ),
            onClick: () =>
              setURLSearchParams([
                { name: 'change_priority_open', value: true },
              ]),
          },
        ]
      : []),
  ]

  return (
    <>
      <SpeedDial
        data-testid={'speed-dial-btn'}
        ariaLabel={`${WORK_ORDER} Speed Dial`}
        className={classes.dial}
        icon={<SpeedDialIcon />}
        classes={{
          actions: open && classes.actionsContainer,
          root: open && classes.actionsArrowBoxShadow,
          fab: classes.fab,
        }}
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
        FabProps={{
          sx: {
            bgcolor: COLOR_TARGET_RED,
            '&:hover': {
              bgcolor: COLOR_TARGET_RED,
            },
          },
        }}
      >
        {actions.map(
          (action, index) =>
            action && (
              <SpeedDialAction
                key={index}
                FabProps={{
                  variant: 'extended',
                  size: 'large',
                  classes: {
                    root: classes.fabRoot,
                  },
                }}
                icon={
                  <>
                    <div className={classes.fabIconWithText}>
                      <div className={classes.iconFlex}>{action.icon}</div>
                      <div>{action.name}</div>
                    </div>
                    {/* This div is to have a small space around the arrow that still keeps the speed dial open on cursor move */}
                    {index === 0 && (
                      <div className={classes.speedDialHiddenDiv} />
                    )}
                  </>
                }
                onClick={action.onClick}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                tooltipTitle=""
              />
            ),
        )}
      </SpeedDial>
      <Snackbar
        open={isSuccess}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={6000}
        onClose={() => setIsSuccess(false)}
        severity="success"
      >
        <Alert
          onClose={() => setIsSuccess(false)}
          severity={'success'}
          sx={{ width: '100%' }}
        >
          {alertMessage}
        </Alert>
      </Snackbar>
      <Snackbar
        open={error}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={6000}
        onClose={() => setError(false)}
        severity="error"
      >
        <Alert
          onClose={() => setError(false)}
          severity="error"
          sx={{ width: '100%' }}
        >
          {alertMessage}
        </Alert>
      </Snackbar>
    </>
  )
}

export default WorkOrderSpeedDial
