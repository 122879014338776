/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import moment from 'moment'
import { makeStyles, createStyles } from '@mui/styles'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'
import { useDispatch, useSelector } from 'react-redux'
import { useAuth } from '@praxis/component-auth'

import {
  Alert,
  Grid,
  Button,
  Typography,
  Card,
  CardHeader,
  CardActionArea,
  CardContent,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  Select,
  MenuItem,
  TextField,
  Stack,
  alpha,
} from '@mui/material'

import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider, DateTimePicker } from '@mui/x-date-pickers'
import { fetchData, postData } from '../../service/HttpService'
import {
  api,
  ADD_ASSIGNMENT,
  NOT_AVAILABLE,
  DEFAULT_ERROR_MESSAGE,
  DATE_FORMAT,
  DATE_TIME_PICKER_FORMAT,
} from '../../globalConstants'
import { setShouldRender } from '../ProgressOverlay/store/actionCreator'
import {
  convertTimestampToLocal,
  formattedTime,
  setTimeString,
} from '../../utils'
import useHashChange from '../../utils/hooks/useHashChange'
import { setURLSearchParams, getURLSearchParams } from '../../windowManager'
import { COLOR_DEFAULT_GREY } from '../../globalColors'

const useStyles = makeStyles((theme) =>
  createStyles({
    grid: {
      padding: theme.spacing(1),
    },
    dialogGrid: {
      margin: theme.spacing(2, 0),
      alignItems: 'center',
    },
    cardContent: {
      padding: theme.spacing(1),
    },
    cardHeader: {
      backgroundColor: alpha(COLOR_DEFAULT_GREY, 0.5),
    },
    actionIcon: {
      margin: theme.spacing(1, 0.5, 0, 0),
    },
  }),
)

const WorkOrderDetailsSchedules = () => {
  const auth = useAuth()
  const dispatch = useDispatch()
  const userInfo = auth.session.userInfo
  const userId = userInfo.lanId?.toUpperCase()
  const classes = useStyles()
  const [addOpen, setAddOpen] = useState(false)
  const [editOpen, setEditOpen] = useState(false)
  const { isDCUser } = useSelector((state) => ({
    ...state.userInfoReducer,
  }))
  const { storeId = '' } = useSelector((state) => state.storeInfoReducer)
  useHashChange('add_assignment_open', setAddOpen)
  useHashChange('edit_assignment_open', setEditOpen)
  const commonGridItemProps = {
    item: true,
    xs: 5,
  }
  const urlSearchParams = getURLSearchParams()
  const woNum = parseInt(urlSearchParams.get('wonum'))
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [assignmentError, setAssignmentError] = useState(false)
  const [assignmentsData, setAssignmentsData] = useState([])
  const [storeShiftData, setStoreShiftData] = useState([
    { displayName: `${userInfo.fullName}`, craft: '' },
  ])
  const [dcShiftData, setDCShiftData] = useState([])
  const [craftData, setCraftData] = useState([])
  const [startDateValue, setStartDateValue] = useState(new Date())
  const currentDate = new Date()
  const [endDateValue, setEndDateValue] = useState(
    new Date(currentDate.setHours(currentDate.getHours() + 1)),
  )
  const [selectedDCPerson, setSelectedDCPerson] = useState('Unassigned')
  const [selectedStorePerson, setSelectedStorePerson] = useState([
    { displayName: `${userInfo.fullName}`, craft: '' },
  ])
  const [craftValue, setCraftValue] = useState('')
  const [hoursTime, setHoursTime] = useState('1 Hr 0 Mins')
  const [dateError, setDateError] = useState(false)
  const [duration, setDuration] = useState('1.0')
  const [resetDialog, setResetDialog] = useState(false)
  const [validEndDate, setValidEndDate] = useState(false)
  const [validStartDate, setValidStartDate] = useState(false)
  const [startDateToEdit, setStartDateToEdit] = useState(null)
  const [endDateToEdit, setEndDateToEdit] = useState(null)
  const [personToEdit, setPersonToEdit] = useState(null)
  const [assignmentId, setAssignmentId] = useState(
    urlSearchParams.get('assignment_id'),
  )
  const editPerson = urlSearchParams.get('selected_person')
  const editCraft = urlSearchParams.get('craft')
  useHashChange('edit_assignment_open', setEditOpen)

  useEffect(() => {
    getAssignments()
    getCrafts()
    setModalData()
  }, [])

  const getAssignments = async () => {
    try {
      dispatch(setShouldRender(true, 'Loading Assignments...'))
      setError(false)
      const response = await fetchData(api.assignments(woNum))
      setAssignmentsData(response.assignmentsdata)
    } catch (error) {
      setError(true)
    } finally {
      dispatch(setShouldRender(false))
      setLoading(false)
    }
  }

  const getCrafts = async () => {
    try {
      dispatch(setShouldRender(true, 'Loading Crafts...'))
      setError(false)
      const response = await fetchData(`/mam/crafts`)
      setCraftData(response.craftdata)
    } catch (error) {
      setError(true)
    } finally {
      dispatch(setShouldRender(false))
      setLoading(false)
    }
  }

  const calculateTime = () => {
    let start = moment(startDateValue, DATE_TIME_PICKER_FORMAT)
    let end = moment(endDateValue, DATE_TIME_PICKER_FORMAT)
    const totalHours = end.diff(start, 'hours')
    const totalMinutes = end.diff(start, 'minutes')
    const minutesInHr = (totalMinutes % 60) / 60
    const totalTimeInHour = (totalHours + minutesInHr).toFixed(2)
    const clearMin = totalMinutes % 60
    const HrText = totalHours === 0 ? ' Hr ' : ' Hrs '
    setHoursTime(totalHours + HrText + clearMin + ' Mins')
    setDateError(Math.sign(moment.duration(end.diff(start))) === -1)
    setDuration(totalTimeInHour)
  }

  useEffect(() => {
    !resetDialog && calculateTime()
  }, [startDateValue, endDateValue])

  const handleStartDateChange = (newValue) => {
    setStartDateValue(newValue)
    setValidStartDate(!moment(newValue).isValid())
    setResetDialog(false)
  }

  const handleEndDateChange = (newValue) => {
    setEndDateValue(newValue)
    setValidEndDate(!moment(newValue).isValid())
    setResetDialog(false)
  }

  const createAssignment = async () => {
    let url = editOpen ? '/maximo/assignment/edit' : '/maximo/assignment/new'
    try {
      dispatch(
        setShouldRender(
          true,
          `${editOpen ? 'Updating' : 'Creating'} Assignment...`,
        ),
      )
      setAssignmentError(false)
      const requestBody = {
        woNum: woNum,
        userId: userId,
        hours: duration,
        scheduledStartDate: startDateValue,
        craft: craftValue,
        siteId: storeId,
        ...(!editOpen &&
          isDCUser && {
            laborCode:
              selectedDCPerson === 'Unassigned'
                ? ''
                : selectedDCPerson || userId,
          }),
        ...(editOpen &&
          isDCUser && {
            laborCode:
              selectedDCPerson === 'Unassigned'
                ? null
                : selectedDCPerson || userId,
          }),
        ...(!isDCUser && {
          laborCode: userId,
        }),
        ...(editOpen && { assignmentId: assignmentId }),
      }
      await postData(url, JSON.stringify(requestBody))
      setURLSearchParams([{ name: 'add_assignment_open', value: false }])
      setURLSearchParams([{ name: 'edit_assignment_open', value: false }])
      await getAssignments()
      setStartDateValue(new Date())
      const currentDate = new Date()
      setEndDateValue(
        new Date(currentDate.setHours(currentDate.getHours() + 1)),
      )
      isDCUser && setSelectedDCPerson('Unassigned')
      setEditOpen(false)
    } catch (error) {
      setAssignmentError(true)
    } finally {
      dispatch(setShouldRender(false))
    }
  }

  const unassign = async () => {
    try {
      dispatch(setShouldRender(true, 'Unassign...'))
      setAssignmentError(false)
      const requestBody = {
        woNum: woNum,
        userId: userId,
        assignmentId: assignmentId,
        siteId: storeId,
      }

      await postData('/maximo/assignment/unassign', JSON.stringify(requestBody))
      closeDialog()
      await getAssignments()
      setEditOpen(false)
    } catch (error) {
      setAssignmentError(true)
    } finally {
      dispatch(setShouldRender(false))
    }
  }

  const addAssignmentHandler = async () => {
    dispatch(setShouldRender(true, 'Add Assignment...'))
    await setModalData()
    setURLSearchParams([{ name: 'add_assignment_open', value: true }])
  }

  const editAssignmentHandler = async (assignment) => {
    const startDate = convertTimestampToLocal(
      assignment.scheduleDate,
      null,
      'timezone',
      'LLLFormat',
    )
    const endDate = moment(
      convertTimestampToLocal(
        assignment.scheduleDate,
        null,
        'timezone',
        'LLLFormat',
      ),
    ).add(assignment.laborHrs, 'hours')
    dispatch(setShouldRender(true, 'Edit Assignment...'))
    await setModalData()
    setHoursTime(setTimeString(formattedTime(assignment.laborHrs)))
    setPersonToEdit(assignment.laborCode)
    setStartDateValue(moment(startDate))
    setEndDateValue(endDate)
    setStartDateToEdit(moment(startDate))
    setEndDateToEdit(endDate)
    setAssignmentId(assignment.assignmentId)
    setCraftValue(assignment.craft)
    setURLSearchParams([
      { name: 'edit_assignment_open', value: true },
      { name: 'craft', value: assignment.craft },
      { name: 'selected_person', value: assignment.laborCode },
      { name: 'assignment_id', value: assignment.assignmentId },
    ])
    setSelectedDCPerson(
      assignment.laborCode ? assignment.laborCode : 'Unassigned',
    )
    setEditOpen(true)
  }

  const setModalData = async () => {
    try {
      editPerson && setSelectedDCPerson(editPerson)
      editPerson && setPersonToEdit(editPerson)
      editOpen && setCraftValue(editCraft)
      if (isDCUser) {
        setAssignmentError(false)
        const shiftResponse = await fetchData(
          `/mam/allshifts/?userid=${userId}`,
        )
        let buildingList = shiftResponse.buildingList
        buildingList.unshift({
          displayName: 'Unassigned',
          laborCode: '',
          personId: 'Unassigned',
        })
        setDCShiftData(buildingList)
        setCraftValue('MT')
      } else {
        setAssignmentError(false)
        const shiftResponse = await fetchData(
          `/mam/myshift/pml?userid=${userId}`,
        )
        const shiftList = shiftResponse.myShiftList
        const shiftResponseData =
          shiftList === null || shiftList.length === 0
            ? storeShiftData
            : shiftList
        setStoreShiftData(shiftResponseData)
        setCraftValue(editCraft || shiftResponseData[0].craft)
      }
    } catch (error) {
      setAssignmentError(true)
    } finally {
      dispatch(setShouldRender(false))
      setLoading(false)
    }
  }

  const closeDialog = () => {
    window.history.back()
    setTimeout(() => {
      setEditOpen(false)
      setCraftValue('')
      setResetDialog(true)
      const currentDate = new Date()
      setStartDateValue(new Date())
      setEndDateValue(
        new Date(currentDate.setHours(currentDate.getHours() + 1)),
      )
      setStartDateToEdit(null)
      setEndDateToEdit(null)
      setHoursTime('1 Hr 0 Mins')
      setDuration('1.0')
      setDateError(false)
      setValidStartDate(false)
      setValidEndDate(false)
    }, 100)
  }

  const renderAddAssignmentDialog = () => {
    return (
      <Dialog
        open={editOpen ? editOpen : addOpen}
        onClose={closeDialog}
        fullWidth
        maxWidth="xs"
        aria-labelledby="add-assignment-dialog-title"
        aria-describedby="add-assignment-dialog-description"
      >
        <DialogTitle>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h6">
              {editOpen ? 'Edit' : 'Add'} Assignment
            </Typography>
            <IconButton
              edge="end"
              color="inherit"
              onClick={closeDialog}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Stack>
        </DialogTitle>
        {!assignmentError ? (
          <>
            <DialogContent>
              <Grid container>
                <Grid item container alignContent="center" sm={6} xs={4}>
                  <Typography>Person:</Typography>
                </Grid>
                <Grid item sm={6} xs={8}>
                  <FormControl fullWidth>
                    {isDCUser ? (
                      <Select
                        value={selectedDCPerson}
                        onChange={(e) => setSelectedDCPerson(e.target.value)}
                        defaultValue={selectedDCPerson}
                      >
                        {dcShiftData &&
                          dcShiftData.map((person, index) => {
                            return (
                              <MenuItem key={index} value={person.personId}>
                                {person.displayName}
                              </MenuItem>
                            )
                          })}
                      </Select>
                    ) : (
                      <Select
                        value={
                          selectedStorePerson.displayName ||
                          storeShiftData[0].displayName
                        }
                        onChange={(e) => setSelectedStorePerson(e.target.value)}
                        defaultValue={
                          selectedStorePerson.displayName ||
                          storeShiftData[0].displayName
                        }
                      >
                        {storeShiftData &&
                          storeShiftData.map((person, index) => {
                            return (
                              <MenuItem key={index} value={person.displayName}>
                                {person.displayName}
                              </MenuItem>
                            )
                          })}
                      </Select>
                    )}
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container className={classes.dialogGrid}>
                {craftValue && (
                  <Grid item xs={4}>
                    <Typography>Craft:</Typography>
                  </Grid>
                )}
                <Grid item xs={8}>
                  <FormControl fullWidth>
                    {isDCUser && selectedDCPerson === 'Unassigned' ? (
                      <Select
                        value={craftValue}
                        onChange={(e) => setCraftValue(e.target.value)}
                        defaultValue={craftValue}
                      >
                        {craftData &&
                          craftData.map(({ craft }, index) => {
                            return (
                              <MenuItem key={index} value={craft}>
                                {craft}
                              </MenuItem>
                            )
                          })}
                      </Select>
                    ) : (
                      isDCUser && (
                        <Grid container justifyContent="flex-end">
                          <Typography>Default Craft</Typography>
                        </Grid>
                      )
                    )}
                    {!isDCUser && craftValue && (
                      <Grid item container justifyContent="flex-end" xs={8}>
                        <Typography>
                          {craftValue === 'SFT' ? 'PML' : craftValue}
                        </Typography>
                      </Grid>
                    )}
                  </FormControl>
                </Grid>
              </Grid>
              <Grid
                container
                justifyContent={'flex-end'}
                className={classes.dialogGrid}
              >
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DateTimePicker
                    label="Start Date"
                    value={startDateValue}
                    onChange={handleStartDateChange}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={dateError || validStartDate}
                        helperText={
                          validStartDate
                            ? `Enter Valid Start Date`
                            : dateError &&
                              `Start Date cannot be greater that End Date`
                        }
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid
                container
                justifyContent={'flex-end'}
                className={classes.dialogGrid}
              >
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DateTimePicker
                    label="End Date"
                    value={endDateValue}
                    onChange={handleEndDateChange}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={dateError || validEndDate}
                        helperText={
                          validEndDate
                            ? `Enter Valid End Date`
                            : dateError &&
                              `End Date should be greater that Start Date`
                        }
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
              {!dateError && !validEndDate && !validStartDate && (
                <Grid container className={classes.dialogGrid}>
                  <Grid item xs={6}>
                    <Typography>Hours:</Typography>
                  </Grid>
                  <Grid item container justifyContent="flex-end" xs={6}>
                    <Typography>{hoursTime}</Typography>
                  </Grid>
                </Grid>
              )}
            </DialogContent>
            <DialogActions>
              <Button variant="outlined" onClick={closeDialog}>
                Cancel
              </Button>
              {editOpen && (
                <Button
                  disabled={!personToEdit && selectedDCPerson !== 'Unassigned'}
                  variant="contained"
                  onClick={unassign}
                >
                  Unassign
                </Button>
              )}
              <Button
                disabled={
                  dateError ||
                  validEndDate ||
                  validStartDate ||
                  (moment(startDateToEdit).isSame(startDateValue) &&
                    moment(endDateToEdit).isSame(endDateValue) &&
                    (personToEdit === dcShiftData[0]?.displayName ||
                      personToEdit === storeShiftData[0]?.displayName))
                }
                variant="contained"
                onClick={createAssignment}
              >
                {editOpen ? 'Update' : 'Save'}
              </Button>
            </DialogActions>
          </>
        ) : (
          <DialogContent>
            <Alert severity="error">{DEFAULT_ERROR_MESSAGE}</Alert>
          </DialogContent>
        )}
      </Dialog>
    )
  }

  return (
    <>
      {error && !loading && (
        <Alert severity="error">Unable to connect to assignment service.</Alert>
      )}
      <Grid
        className={classes.grid}
        container
        justifyContent="flex-end"
        alignItems="center"
      >
        <Button
          variant="contained"
          onClick={addAssignmentHandler}
          data-testid="add-assignment-id"
        >
          {ADD_ASSIGNMENT}
        </Button>
        {renderAddAssignmentDialog()}
      </Grid>
      {assignmentsData?.length > 0 && (
        <Grid container>
          {assignmentsData.map((assignment, index) => {
            return (
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                className={classes.cardContent}
                key={index}
                data-testid="assignment-list"
              >
                <Card>
                  <CardActionArea
                    onClick={() => editAssignmentHandler(assignment)}
                  >
                    <CardHeader
                      className={classes.cardHeader}
                      action={
                        <ChevronRightIcon className={classes.actionIcon} />
                      }
                      title={`${assignment.firstName ?? 'Unassigned'} ${
                        assignment.lastName ?? ''
                      }`}
                    />
                    <Divider />
                    <CardContent>
                      <Grid container>
                        <Grid {...commonGridItemProps}>
                          <Typography>
                            <strong>Craft:</strong>
                          </Typography>
                        </Grid>
                        <Typography>
                          {assignment.craft === 'SFT'
                            ? 'PML'
                            : assignment.craft}
                        </Typography>
                      </Grid>
                      <Grid container>
                        <Grid {...commonGridItemProps}>
                          <Typography>
                            <strong>Hours:</strong>
                          </Typography>
                        </Grid>
                        <Typography>{assignment.laborHrs}</Typography>
                      </Grid>
                      <Grid container>
                        <Grid {...commonGridItemProps}>
                          <Typography>
                            <strong>Start:</strong>
                          </Typography>
                        </Grid>
                        <Typography>
                          {assignment.scheduleDate
                            ? convertTimestampToLocal(
                                assignment.scheduleDate,
                                null,
                                'timezone',
                              )
                            : NOT_AVAILABLE}
                        </Typography>
                      </Grid>
                      <Grid container>
                        <Grid {...commonGridItemProps}>
                          <Typography>
                            <strong>End:</strong>
                          </Typography>
                        </Grid>
                        <Typography>
                          {assignment.scheduleDate
                            ? moment(
                                convertTimestampToLocal(
                                  assignment.scheduleDate,
                                  null,
                                  'timezone',
                                  'endDate',
                                ),
                              )
                                .add(assignment.laborHrs, 'hours')
                                .format(DATE_FORMAT)
                            : NOT_AVAILABLE}
                        </Typography>
                      </Grid>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
            )
          })}
        </Grid>
      )}
    </>
  )
}

export default WorkOrderDetailsSchedules
